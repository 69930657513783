import {
    FormControl,
    Flex,
    FormLabel,
    Select,
    FormHelperText,
    FormErrorMessage,
    Input,
    Box,
} from '@chakra-ui/react'
export interface IFormControlCompProps {
    error?: string
    placeHolder?: string
    label: string
    isSelect: boolean
    input?: string
    handleInputChange: any
    list?: any[]
    name: string
}
function FormControlComp({
    label,
    isSelect,
    error,
    input,
    handleInputChange,
    list,
    placeHolder,
    name,
}: IFormControlCompProps) {
    console.log('list', list)
    return (
        <FormControl isInvalid={error ? true : false}>
            <Flex alignItems="center">
                <FormLabel htmlFor="text" mr={8} w="25%">
                    {label}
                </FormLabel>
                <Box w="75%">
                    {isSelect && list && list?.length > 0 ? (
                        <>
                            <Input
                                type="text"
                                name={name}
                                placeholder="Maak keuze"
                                onChange={handleInputChange}
                                value={input}
                                list={label}
                                autoComplete="off"
                            />

                            <datalist id={label}>
                                {list?.map((item) => (
                                    <option key={item.id}>{item.name}</option>
                                ))}
                            </datalist>
                        </>
                    ) : (
                        <Input
                            id="id"
                            name={name}
                            type="text"
                            value={input}
                            onChange={handleInputChange}
                            autoComplete="off"
                            placeholder={placeHolder}
                        />
                    )}
                    {error && <FormErrorMessage>{error}.</FormErrorMessage>}
                </Box>
            </Flex>
        </FormControl>
    )
}

export default FormControlComp
