import { call, put } from 'redux-saga/effects'
import { setKennisgebied } from './mainDataSlice'

const getKennisgebiedData = async (): Promise<any> => {
    const res = await fetch('/api/kennisgebied', {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
    return res.json()
}

export function* kennisgebiedSaga(): any {
    try {
        yield put(setKennisgebied({ loading: true }))
        const data = yield call(getKennisgebiedData)
        // const data = yield resData.dat
        console.log('items 999', data)
        yield put(setKennisgebied({ loading: false, kennisgebied: data }))
    } catch (error) {
        yield put(
            setKennisgebied({
                error: { isError: true, value: error },
                loading: false,
            })
        )
    }
}
