import { call, put, takeLatest } from 'redux-saga/effects'
import IId from '../selectedNode/id.interface'
import { getMainDataAction, setIds } from './mainDataSlice'

const getMainData = async (): Promise<any> => {
    const res = await fetch('/api/ids', {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
    return res.json()
}
const makeValuesLowerCas = (data: IId[]) => {
    const lowerCaseData = data.map((obj) => {
        const objEntries = Object.entries(obj).map(([key, value]) => {
            if (value && value.length > 0) {
                return [key, value.toLowerCase()]
            } else return [key, value]
        })

        const newObj = Object.fromEntries(objEntries)
        return newObj
    })
    return lowerCaseData
}
export function* mainDataSaga(): any {
    try {
        yield put(setIds({ loading: true }))
        const data = yield call(getMainData)
        console.log('data json', data)
        // const ids = yield makeValuesLowerCas(data)

        yield put(setIds({ loading: false, ids: data }))
    } catch (error) {
        yield put(
            setIds({
                error: { isError: true, value: error },
                loading: false,
            })
        )
    }
}

export function* mainDataWatcher() {
    yield takeLatest(getMainDataAction, mainDataSaga)
}
