import { Box, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import FrontPagePlaceHolder from '../components/FrontPagePlaceHolder'
import TableView from '../components/TableView'
import Treeview from '../components/TreeView'
import { getMainDataAction } from '../redux/mainData/mainDataSlice'
import { useAppDispatch, useAppSelector } from '../redux/store'

function Home() {
    const dispatch = useAppDispatch()

    const ids = useAppSelector((state) => state.mainState.ids)
    const node = useAppSelector((state) => state.selectedNodeState.node)
    const [selectedkey, setSelectedkey] = useState('')
    const [data, setData] = useState()

    useEffect(() => {
        dispatch(getMainDataAction())
    }, [])
    function TreeviewClicked(key: string) {
        setSelectedkey(key)
    }
    return (
        // <Flex flex={1} px="15%">
        <Flex boxShadow="2xl" w="1000px" py={1} mt="84px">
            <Flex bg="white" p={4}>
                {ids && ids.length > 0 && (
                    <Treeview
                        nodeclicked={TreeviewClicked}
                        setData={setData}
                    ></Treeview>
                )}
            </Flex>
            <Flex flex={1} pr={4} pb={8}>
                {node ? (
                    <Box w="100%">
                        <TableView data={data} />
                        <Flex
                            bg="rgba(255,255,204)"
                            my={8}
                            fontSize={13}
                            px={4}
                            py={1}
                            justifyContent="center"
                            boxShadow="lg"
                        >
                            <Text>
                                Meer weten over dit onderdeel of kennis delen?
                                Vraag het de contactpersoon of bezoek binnenkort
                                Viva Topics.
                            </Text>
                        </Flex>
                    </Box>
                ) : (
                    <FrontPagePlaceHolder />
                )}
            </Flex>
        </Flex>
    )
}

export default Home
