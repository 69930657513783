import { call, put } from 'redux-saga/effects'
import { setAdmin } from './mainDataSlice'

const getAdminData = async (): Promise<any> => {
    const res = await fetch('/api/admin', {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
    return res.json()
}

export function* adminDataSaga(): any {
    try {
        yield put(setAdmin({ loading: true }))
        const data = yield call(getAdminData)
        const admins = data[0]
        console.log('admin admin', data)
        yield put(setAdmin({ loading: false, admin: admins }))
    } catch (error) {
        yield put(
            setAdmin({
                error: { isError: true, value: error },
                loading: false,
            })
        )
    }
}
