import { Text, Flex, VStack, Box, Button, Spinner } from '@chakra-ui/react'
import React from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import { opencreateOrEditIdModal } from '../redux/createOrEditId/createOrEditId'
import IId from '../redux/selectedNode/id.interface'
import { useAppDispatch, useAppSelector } from '../redux/store'
import AlertBanner from './AlertBanner'
import EditModal from './EditModal/EditModal'
import MultipleRows from './MultipleRows'

export interface ITableViewProps {
    data: any
}
function TableView({ data }: ITableViewProps) {
    const isAdmin = useAppSelector((state) => state.userState.isAdmin)
    const isOpen = useAppSelector((state) => state.createOrEditId?.openModal)
    const loading = useAppSelector((state) => state.createOrEditId?.loading)
    const defaultIdStatus = useAppSelector(
        (state) => state.mainState.defaultIdStatus
    )
    const breadCrump = useAppSelector(
        (state) => state.selectedNodeState.breadCrump
    )
    const dispatch = useAppDispatch()
    const setIsOpen = (openModal: boolean): void => {
        dispatch(
            opencreateOrEditIdModal({ openModal, error: null, loading: false })
        )
    }
    const onClose = () =>
        dispatch(
            opencreateOrEditIdModal({
                openModal: false,
                error: null,
                loading: false,
                isUpdate: false,
                editFormState: {
                    id: { input: '' },
                    categories: { input: '' },
                    kennisgebied: { input: '' },
                    onderdeels: { input: '' },
                    verwijzing: { input: '' },
                    id_status: { input: '' },
                },
            })
        )
    const cancelRef = React.useRef()
    const onOpen = () => setIsOpen(true)
    console.log('defaultIdStatus', defaultIdStatus)
    const rowsData = useAppSelector((state) => state.selectedNodeState.node)
    let idStatuses = useAppSelector((state) => state.mainState.idStatus)
    idStatuses = idStatuses?.map((item: any) => item.name)
    const filteredDefaultIdStatus = defaultIdStatus?.map(
        (item: any) => item.id_status
    )

    let rows = rowsData && (rowsData[0] as IId[])
    // rows = rows?.filter(
    //     (item) => item.ID_status.toUpperCase() !== 'NOG TE VALIDEREN'
    // )
    const filteredRows: any[] = filteredDefaultIdStatus?.map((el: string) => {
        const foundedRows = rows?.filter(
            (item) => item.ID_status.toUpperCase() === el.toUpperCase()
        )
        return { name: el, foundedRows }
    })

    console.log('nonNulRows ==> ', filteredRows)

    const trefwordenItems =
        filteredRows &&
        filteredRows.length > 0 &&
        filteredRows?.filter((item) => item?.foundedRows?.length > 0)[0]
            ?.foundedRows[0]

    if (loading) {
        return (
            <Flex
                flex={1}
                bg="white"
                py={4}
                boxShadow="xl"
                justifyContent="center"
                alignItems="center"
            >
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                />
            </Flex>
        )
    }
    return (
        <Flex flex={1} bg="white" py={4} boxShadow="xl">
            <VStack align="stretch" flex={1}>
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    py={1}
                    pr={1}
                >
                    <Text fontSize="sm" pl={2}>
                        {breadCrump}
                    </Text>
                    {isAdmin && (
                        <Button
                            leftIcon={<FaPencilAlt />}
                            colorScheme="teal"
                            variant="link"
                            onClick={onOpen}
                        >
                            Nieuw
                        </Button>
                    )}
                </Flex>
                <Flex
                    justifyContent="space-between"
                    py={2}
                    bg="rgb(0 200 182)"
                    px={4}
                >
                    <Text>{rows && rows?.length > 0 && rows[0].Onderdeel}</Text>
                    <Text>Actuele versie</Text>
                </Flex>
                {rows && rows.length > 0 ? (
                    <Box>
                        {filteredRows &&
                            filteredRows?.length > 0 &&
                            filteredRows.map((item: any) => (
                                <MultipleRows
                                    key={item.name}
                                    title={item.name}
                                    data={item.foundedRows!!}
                                />
                            ))}

                        <Flex
                            // height="25%"
                            px={4}
                            py={2}
                            alignItems="center"
                            fontWeight="normal"
                            fontSize={15}
                        >
                            <Flex
                                w="30%"
                                justifyContent="start"
                                alignItems="center"
                                fontSize={15}
                            >
                                <Text>Trefwoorden</Text>
                            </Flex>
                            <Flex
                                w="70%"
                                justifyContent="start"
                                alignItems="center"
                            >
                                <Text>
                                    {trefwordenItems &&
                                        trefwordenItems.Trefwoorden}
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex
                            px={4}
                            py={2}
                            alignItems="center"
                            fontWeight="normal"
                            fontSize={15}
                        >
                            <Flex
                                w="30%"
                                justifyContent="start"
                                alignItems="center"
                                fontSize={15}
                            >
                                <Text>Contactpersoon</Text>
                            </Flex>
                            <Flex
                                w="70%"
                                justifyContent="start"
                                alignItems="center"
                            >
                                <Text>
                                    {trefwordenItems &&
                                        trefwordenItems.ID_contactpersoon}
                                </Text>
                            </Flex>
                        </Flex>
                        <EditModal isOpen={isOpen} onClose={onClose} />
                    </Box>
                ) : (
                    <AlertBanner
                        title="Data error"
                        description="Er zijn geen resultaten gevonden"
                        variant="subtle"
                        status="error"
                    />
                )}
            </VStack>
            <EditModal
                isOpen={isOpen ?? false}
                onClose={onClose}
                cancelRef={cancelRef}
            />
        </Flex>
    )
}

export default TableView
