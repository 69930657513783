import { VStack } from '@chakra-ui/react'
import React from 'react'
import { Item } from 'react-simple-tree-menu'
import {
    ICreateOrEditId,
    IEditFormState,
    setcreateOrEditId,
} from '../../redux/createOrEditId/createOrEditId'
import { useAppDispatch, useAppSelector } from '../../redux/store'

import FormControlComp from './FormControlComp'

export interface IEditFormProps {
    state: IEditFormState | undefined
    setState: (state: IEditFormState) => {
        payload: ICreateOrEditId
        type: string
    }
}

export default function EditForm({ state, setState }: IEditFormProps) {
    const { kennisgebied, onderdeels, categories, defaultIdStatus } =
        useAppSelector((state) => state.mainState)

    const defaultStatus = defaultIdStatus.map((item: any) => ({
        ...item,
        name: item.id_status.charAt(0).toUpperCase() + item.id_status.slice(1),
    }))

    const { editFormState } = useAppSelector((state) => state.createOrEditId)
    const dispatch = useAppDispatch()
    // const [state, setState] = useState<IEditFormState>()

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target
        const value = target.value
        const name = target.name

        dispatch(setcreateOrEditId({ [name]: { input: value } }))
    }

    // const isError = input === ''
    console.log('state', editFormState?.categories)

    return (
        <VStack spacing={4} align="stretch">
            <FormControlComp
                error={state?.id?.error}
                label="ID"
                name="id"
                isSelect={false}
                input={editFormState?.id?.input}
                handleInputChange={handleInputChange}
            />
            <FormControlComp
                error={state?.title?.error}
                label="Titel"
                name="title"
                placeHolder="Applicatie"
                isSelect={false}
                input={editFormState?.title?.input ?? 'Application'}
                handleInputChange={handleInputChange}
            />
            <FormControlComp
                error={state?.id?.error}
                label="Status"
                name="id_status"
                isSelect={true}
                input={editFormState?.id_status?.input ?? ''}
                list={defaultStatus}
                handleInputChange={handleInputChange}
            />
            <FormControlComp
                error={state?.kennisgebied?.error}
                label="Kennisgebied"
                name="kennisgebied"
                isSelect={true}
                list={kennisgebied}
                input={editFormState?.kennisgebied?.input ?? ''}
                handleInputChange={handleInputChange}
            />
            <FormControlComp
                error={state?.categories?.error}
                label="Categorie"
                name="categories"
                isSelect={true}
                input={editFormState?.categories?.input ?? ''}
                list={categories}
                handleInputChange={handleInputChange}
            />
            <FormControlComp
                error={state?.onderdeels?.error}
                label="Onderdeel"
                list={onderdeels}
                isSelect={true}
                input={editFormState?.onderdeels?.input ?? ''}
                name="onderdeels"
                handleInputChange={handleInputChange}
            />
            <FormControlComp
                error={state?.verwijzing?.error}
                label="Verwijzing"
                name="verwijzing"
                input={editFormState?.verwijzing?.input ?? ''}
                isSelect={true}
                handleInputChange={handleInputChange}
            />
            <FormControlComp
                error={state?.trefwoorden?.error}
                label="Trefwoorden"
                name="trefwoorden"
                input={editFormState?.trefwoorden?.input ?? ''}
                isSelect={true}
                handleInputChange={handleInputChange}
            />
            <FormControlComp
                error={state?.contactpersoon?.error}
                label="Contactpersoon"
                name="contactpersoon"
                input={editFormState?.contactpersoon?.input ?? ''}
                isSelect={true}
                handleInputChange={handleInputChange}
            />
        </VStack>
    )
}
